import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBN1UUmI0X34z4kJfz1qMVe5ZVIGQVKI1w',
		authDomain: 'cgl-hkscan-test.firebaseapp.com',
		projectId: 'cgl-hkscan-test',
		storageBucket: 'cgl-hkscan-test.appspot.com',
		messagingSenderId: '32063182149',
		appId: '1:32063182149:web:850f5dee8a34fd79275a2c'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDy0i471UjbcHOr4y-linDDG4gWJ5QtYl4',
		authDomain: 'cgl-hkscan-production.firebaseapp.com',
		projectId: 'cgl-hkscan-production',
		storageBucket: 'cgl-hkscan-production.appspot.com',
		messagingSenderId: '370355655696',
		appId: '1:370355655696:web:21ca7b1b13570312fe2695'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;