const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-welcome',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Velkommen første del af hygiejnetræningen. Det er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to first part of the hygiene retraining. This is the first of two modules, you have to complete today.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-points',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `n this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Når du får point, kan du også få kyllinger. I toppen af skærmen kan du se, hvor mange kyllinger og point du har.`,
			en: `As you get points, you'll also gain chickens. At the top of your screen, you can see how many points and chickens, you have.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Husk, du skal have tre kyllinger for at gennemføre den her del af træningen.`,
			en: `You need to get three chickens to advance to the next module. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-first-task',
		background: 'dressing-room',
		text: {
			da: `Vi starter med påklædning. På næste billede skal du trykke på de ting, som er forkerte.`,
			en: `We start with how you're dressed. On the next image, click on the issues.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-person',
		layout: 's1-m3-person',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in-no-bench',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'watch',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hair'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'open-smock'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-lockers',
		background: 'dressing-room',
		text: {
			da: `Inden vi går ind i produktionen, så skal vi lige forbi skabene.`,
			en: `Before entering production, we have to talk about the lockers.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-private-and-work-lockers',
		background: 'dressing-room',
		text: {
			da: `Du har et skab til privat tøj og et sted til arbejdstøj.`,
			en: `You have a locker for private clothes and one for work clothes.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-not-in-private-locker',
		layout: 's1-m3-items-not-in-private-locker',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører IKKE til i skabet til privat tøj?`,
			en: `Which of the following does NOT belong in the locker for private clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // everyday shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // flip flops
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // apron
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // hairnet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-in-private-locker',
		layout: 's1-m3-items-in-private-locker',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører til i skabet til privat tøj?`,
			en: `Which of the following should go in the locker for private clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // safey glasses
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // white work clothes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // halsedisse og hue
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-correct-storage',
		background: 'dressing-room',
		text: {
			da: `Det er vigtigt at opbevare ting de rigtige steder. På den måde undgår du, at værktøj, tøj og skabe bliver forurenet.`,
			en: `It's important to place things in the right place. That way you can keep tools, clothes, and lockers from getting contaminated.`
		}
	}),	
Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-hand-hygiene',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `En af de vigtigste regler er at have god håndhygiejne. Det handler både om at vaske hænder tit, men også om at gøre det ordentligt.`,
			en: `Proper hand hygiene is one of the most important things. That means you must always wash your hands before entering production, but also that you have to do it the right way.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-washing-hands-areas',
		layout: 's1-m3-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		subtype: 'images',
		shuffleOptions: false,
		text: {
			da: `Der er især tre områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Three areas are especially easy to forget, when washing hands. What are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-when-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvor tit skal du huske at vaske hænder?`,
			en: `How often must you wash your hands?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hver anden time`,
					en: `Every second hour`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Når de er blevet forurenet`, 
					en: `When they've been contaminated`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig! For eksempel hvis du håndterer affald, rører ved dit ansigt eller samler noget op fra gulvet.`,
								en: `Exactly! For instance if you use a ladder, touch your face, or pick something up from the floor.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Efter pauser og toiletbesøg`,
					en: `After breaks and being to the toilet`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Når jeg går forbi en håndvask`,
					en: `When I walk past a sink`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Hver time`,
					en: `Every hour`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Hvert kvarter`,
					en: `Every fifteen minutes`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-person2',
		layout: 's1-m3-person2',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Se om du kan spotte de 4 fejl på udstyret her. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `See if you can spot the 4 issues with the equipment here. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'apron',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'scarf'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'clothes'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-remember',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvad skal du huske her inden du går ind i produktionen?`,
			en: `What must you remember to do here before entering production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `At vaske hænder`,
					en: `Wash my hands`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At rette på mit hårnet`, 
					en: `Straigthen my hairnet`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det behøver du kun hvis det sidder dårligt. Men husk at vaske hænder bagefter.`,
								en: `Only if it doesn't fit properly. But remember to wash your hands afterwards.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `At high five mine kollegaer`,
					en: `High fiving my colleagues`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At sige det hemmelige kodeord`,
					en: `Say the secret password`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m3-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In a hygiene lock, you must always wash your hands. What is the correct order to do that in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med papir`,
					en: `Dry hands with paper towels`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid papir i skraldespand`,
					en: `Put paper towels in bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Brug eventuelt håndsprit`,
					en: `Use sanitizer`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-production-ready',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Nu er vi klar til at gå i gang med at arbejde. Det gør vi i næste modul.`,
			en: `Now we're ready to start work. We'll do that in the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-you-make-food',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Husk, du fremstiller fødevarer som andre skal spise, og som du måske også selv skal spise.`,
			en: `Remember, you make foods that others - and possibly yourself - will eat.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-finish',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Godt gået! Du er nu igennem første del af gentræningen. Hvis du har fået tre kyllinger, kan du fortsætte - ellers skal du tage det her modul en gang til.`,
			en: `Well done! You have made it through the first part of the retraining. If you have three chickens, you can continue to the next  module. If not, you have to retake this module.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m3-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};