// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const errorUiTexts = {
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.',
		pl: 'Błąd: nieznany błąd.',
		de: 'Fehler: unbekannter Fehler.'
	},

	/* Cookies not accepted */
	cookiesNotAccepted: {
		da: `Du skal godkende brug af cookies for at kunne logge ind.`,
		en: 'You have to accept use of cookies to log in.',
		pl: 'Aby się zalogować, musisz zaakceptować użycie plików cookie.',
		de: 'Um sich anzumelden, müssen Sie die Verwendung von Cookies akzeptieren.'
	},

	/* Create user (facilitator) */
	emptyFields: {
		da: 'Du skal udfylde alle felter.',
		en: 'Please fill out all fields.',
		pl: 'Proszę wypełnić wszystkie pola.',
		de: 'Bitte fülle alle Felder aus.'
	},
	emailExists: {
		da: 'Der findes allerede en bruger med denne email.',
		en: 'This email is already in use.',
		pl: 'Ten email jest już w użyciu.',
		de: 'Diese E-Mail-Adresse wird schon verwendet.'
	},
	invalidPassword: {
		da: 'Dit password skal være på mindst 8 tegn.',
		en: 'Your password has to be at least 8 characters.',
		pl: 'Twoje hasło musi mieć co najmniej 8 znaków.',
		de: 'Ihr Passwort muss mindestens 8 Zeichen lang sein.'
	},
	noPasswordMatch: {
		da: 'Password skal være ens i begge felter.',
		en: 'Passwords do not match.',
		pl: 'Hasła nie pasują do siebie.',
		de: 'Passwörter stimmen nicht überein.'
	},

	/* Login / reset password (facilitator) */
	emailNotFound: {
		da: 'Der findes ingen bruger med denne email.',
		en: 'There is no user linked to this email.',
		pl: 'Żaden użytkownik nie jest powiązany z tym e-mailem.',
		de: 'Mit dieser E-Mail ist kein Benutzer verknüpft.'
	},
	invalidEmail: {
		da: 'Din email er ikke gyldig.',
		en: 'This email is not valid.',
		pl: 'Ten e-mail jest nieprawidłowy.',
		de: 'Diese E-Mail ist ungültig.'
	},
	invalidEmailPasswordFormat: {
		da: 'Fejl. Check om din email og password er korrekte.',
		en: 'Error. Check if your email and password are correct.',
		pl: 'Błąd. Sprawdź, czy Twój adres e-mail i hasło są poprawne.',
		de: 'Fehler. Überprüfen Sie, ob Ihre E-Mail-Adresse und Ihr Passwort korrekt sind.'
	},
	
	/* Login (player) */
	emptyUserId: {
		da: 'Husk at udfylde dit medarbejder-nr.',
		en: 'Please enter your employee no.',
		pl: 'Proszę podać swój numer pracownika.',
		de: 'Bitte geben Sie Ihre Mitarbeiter-Nr. ein.'
	},
	invalidUserId: {
		da: 'Det indtastede nummer er forkert. Husk det skal være 6 cifre.',
		en: 'Invalid number. Remember it must be 6 digits.',
		pl: 'Nieprawidłowy numer. Pamiętaj, że musi to być 6 cyfr.',
		de: 'Ungültige Nummer. Denken Sie daran, dass es 6 Ziffern sein muss.'
	}
};

module.exports = {
	errorUiTexts
};