
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {getPlayerModuleSessionData} from 'helpers/module-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {moduleComponents} from 'data/modules-components';
import ProgressBar from 'components/ui/progress-bar/progress-bar';
import Popup from 'components/ui/popup/popup';
import Button from 'components/ui/button/button';
import ModuleNav from 'components/ui/dev-tools/module-nav';
import Instructions from 'components/game/module/engines/instructions/instructions';
import Feedback from 'components/ui/feedback/feedback';
import './module.scss';

const Module = (props) => {
	const {
		hasQueuedEffects,
		languageId,
		backgroundStatus,
		moduleData, 
		taskData, 
		playerData,
		feedbackData,
		popupData,
		updateLoggedTime,
		handleGoToTask,
		handleGoToGamePage,
		handleInstantTaskEffects,
		handleCompleteTask,
		processQueuedEffects,
		handleCompleteModuleSession
	} = props;

	/* Get player data for this module session */
	const playerModuleSession = getPlayerModuleSessionData(playerData, moduleData.id);

	/* Get player data for the module session task */
	let playerTaskData = (playerModuleSession && playerModuleSession.tasks
		? playerModuleSession.tasks.find((t) => {return t.taskId === taskData.taskId;})
		: null
	);

	/* Get next module task id */
	const taskIndex = moduleData.tasks.findIndex((task) => {return task.id === taskData.id;});
	const nextTaskId = (taskIndex + 1 < moduleData.tasks.length 
		? moduleData.tasks[taskIndex + 1].id
		: null
	);

	/* Toggle progress bar, update when new module task */
	const [showProgressBar, setShowProgressBar] = useState(false);

	const [shouldFadeSpeech, setShouldFadeSpeech] = useState(false);

	/* Toggle next btn, update on new module task */
	const [nextBtnIsActive, setNextBtnIsActive] = useState(false);
	const updateNextBtnStatus = () => {
		const newNextBtnIsActive = (
			(taskData.isSolveToContinue === false || (playerTaskData && playerTaskData.isCompleted)) 
				? true : false
		);
		if (newNextBtnIsActive) setNextBtnIsActive(newNextBtnIsActive);
	};

	/**
	 * Effects on new task
	 */
	useEffect(() => {
		/* Hide / show next button */
		if (nextBtnIsActive) {
			setNextBtnIsActive(false);
		} else {
			updateNextBtnStatus();
		}
		
		/* Hide / show progress bar */
		let showProgressBar = (taskData.showProgressBar === true ? true : false);
		if (
			taskData.hasOwnProperty('showProgressBarIfCompleted') && 
			taskData.showProgressBarIfCompleted !== showProgressBar
		) {
			const playerModuleSession = getPlayerModuleSessionData(playerData, moduleData.id);
			const playerTaskData = (playerModuleSession && playerModuleSession.tasks
				? playerModuleSession.tasks.find((t) => {return t.taskId === taskData.taskId;})
				: null
			);
			const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);
			if (isCompleted) showProgressBar = taskData.showProgressBarIfCompleted;
		}
		setShowProgressBar(showProgressBar);

		/* Scroll to top */
		window.scrollTo({top: 0});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * 
	 */
	useEffect(() => {
		updateNextBtnStatus();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextBtnIsActive, playerTaskData]);

	let timeout = useRef(null);
	const handleNextTaskButtonPress = (taskId) => {
		// If current task is instructions speech type, then we wait before continuing to next task
		// This is to allow for speech bubble to fade out first.
		if (taskData.type === 'instructions' && taskData.textType === 'speech') {
			setShouldFadeSpeech(true);
			timeout.current = setTimeout(() => {
				setShouldFadeSpeech(false);
				handleGoToTask(taskId);
				clearTimeout(timeout.current);
			}, 500);
		} else {
			handleGoToTask(taskId);
		}
	};

	useEffect(() => {
		return () => {
			/* Component will unmount */
			if (timeout.current) clearTimeout(timeout.current);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Next button */
	const showNextBtn = (
		nextBtnIsActive &&
		!hasQueuedEffects && 
		!feedbackData && 
		!popupData
	);
	let nextBtnTypes = ['next'];
	if (playerTaskData && playerTaskData.isCompleted) {
		/* Animate next button when completing a task */
		nextBtnTypes.push('animate');
	} else {
		/* Fade-in next button on welcome / instructions / image pages */
		if (!taskData.isSolveToContinue) {
			nextBtnTypes.push('delay');
		}
	}
	let nextBtnText = playerUiTexts.continue;
	if (taskData.nextBtnTypes) nextBtnTypes = taskData.nextBtnTypes;
	if (taskData.nextBtnText) nextBtnText = taskData.nextBtnText;

	/* Task type component */
	let TaskComponent = Instructions;
	if (moduleComponents.hasOwnProperty(taskData.type)) TaskComponent = moduleComponents[taskData.type];

	return (
		<div className={'Module ' + languageId + ' ' + (taskData.background 
			? taskData.background : moduleData.background)}
		>
			{backgroundStatus === 'show' &&
			<TaskComponent 
				isShowingFeedbackOrPopup={(feedbackData || popupData ? true : false)}
				languageId={languageId}
				backgroundStatus={backgroundStatus}
				playerData={playerData}
				playerTaskData={playerTaskData}
				moduleData={moduleData} 
				taskData={taskData}
				updateLoggedTime={updateLoggedTime}
				setShowProgressBar={setShowProgressBar}
				handleInstantTaskEffects={handleInstantTaskEffects}
				handleCompleteTask={handleCompleteTask}
				handleGoToTask={handleGoToTask}
				shouldFadeSpeech={shouldFadeSpeech}
			/>}
			
			<div className="Module-header">
				{/* Modules overview button */}
				<div className="Module-homeBtn" onClick={() => {handleGoToGamePage('modules-overview');}} />

				{/* Progress bar */}
				{showProgressBar && 
					<div className="Module-progressBar">
						<ProgressBar 
							moduleId={moduleData.id}
							points={((playerModuleSession && playerModuleSession.points) 
								? playerModuleSession.points : 0)} 
						/>
					</div>
				}
			</div>

			{/* Next task button */}
			{(showNextBtn && nextTaskId) && <div className={'Module-nextBtn ' + nextBtnTypes.join(' ')}>
				<Button 
					classes={nextBtnTypes} 
					text={nextBtnText ? getText(nextBtnText, languageId) : null}
					onClick={() => {handleNextTaskButtonPress(nextTaskId);}} />
			</div>}

			{/* Finish module button */}
			{(showNextBtn && !nextTaskId) && <div className="Module-nextBtn finish">
				<Button 
					classes={['confirmTask', 'default-case']} 
					text={getText(playerUiTexts.finishBtn, languageId)}
					onClick={() => {handleCompleteModuleSession();}} />
			</div>}

			{/* Developer module navigation */}
			{(appConfig.env !== 'production') && 
				<ModuleNav moduleData={moduleData} taskId={taskData.id} handleGoToTask={handleGoToTask} />
			}

			{/* Feedback */}
			{feedbackData && 
				<Feedback 
					languageId={languageId}
					taskId={taskData.id}
					feedbackData={feedbackData} 
					onClick={() => {processQueuedEffects();}} 
				/>
			}

			{/* Popup */}
			{popupData &&
				<Popup 
					type={(popupData.type ? popupData.type : null)}
					title={(popupData.title ? popupData.title : null)}
					text={(popupData.text ? popupData.text : null)}
					togglePopup={processQueuedEffects}
					buttons={[
						{
							classes: ['login'],
							text: getText(playerUiTexts.ok, languageId),
							action: processQueuedEffects
						}
					]}
				/>
			}
		</div>
	);
};

Module.propTypes = {
	hasQueuedEffects: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	feedbackData: PropTypes.object,
	popupData: PropTypes.object,
	updateLoggedTime: PropTypes.func.isRequired,
	handleGoToTask: PropTypes.func.isRequired,
	handleGoToGamePage: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	processQueuedEffects:PropTypes.func.isRequired,
	handleCompleteModuleSession: PropTypes.func.isRequired,
};

export default Module;
