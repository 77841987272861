const moduleImages = [
	'modules/tasks/multiple-choice/s1-m4-allergies/option-1.svg',
	'modules/tasks/multiple-choice/s1-m4-allergies/option-2.svg',
	'modules/tasks/multiple-choice/s1-m4-allergies/option-3.svg',
	'modules/tasks/multiple-choice/s1-m4-allergies/option-4.svg',
	'modules/tasks/multiple-choice/s1-m4-allergies/option-5.svg',
	'modules/tasks/multiple-choice/s1-m4-filled-containers/option-1.svg',
	'modules/tasks/multiple-choice/s1-m4-filled-containers/option-2.svg',
	'modules/tasks/multiple-choice/s1-m4-filled-containers/option-3.svg',
	'modules/tasks/multiple-choice/s1-m4-filled-containers/option-4.svg',
	'modules/tasks/multiple-choice/s1-m4-filled-containers/option-5.svg',
	'modules/tasks/multiple-choice/s1-m4-filled-containers/option-6.svg',

	'modules/tasks/spot-errors/s1-m4-production-issues/background.svg'
];

export {
	moduleImages
};