const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i selve produktionen.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to talk about hygiene inside the production facilities.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-how-to-handle-products',
		text: {
			da: `Det handler om, hvordan du skal håndtere produkter i produktionen. Og husk: vi laver mad til andre og os selv, og derfor er hygiejne vigtigt.`,
			en: `We'll also be talking about how to handle product. And remember, we make foods for others and ourselves, and that is why hygiene is important.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stars',
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre kyllinger for at gennemføre et modul.`,
			en: `Let's start! Remember, you must get three chickens to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-hygiene-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejnen, når vi producerer fødevarer?`,
			en: `Why must we be especially aware of hygiene when making foods?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Because of the law`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because bad hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fordi kunderne forventer det`,
					en: `Because the customers expect it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-production-hygiene-issues',
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at the production. On the next image, see if you can spot the things that can be problematic when it comes to hygiene.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-hygiene-issues',
		layout: 's1-m2-production-hygiene-issues',
		background: 'production',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 5 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'box-on-floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knife'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'red-box',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Røde kasser må ikke stå på båndet.`,
								en: `Red crates are not allowed on the belt.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'towel'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'meat'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-meat-on-floor',
		text: {
			da: `Der lå en kylling på gulvet i den forrige opgave – hvad skal du gøre med den?`,
			en: `There was a chicken on the floor in the previous task - what should you do about it?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Smide den ud i almindelig skraldespand`,
					en: `Put it in with regular trash`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Sende den til gulvspild (rød kasse)`, 
					en: `Send it to floor waste (red box)`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Lægge den tilbage på båndet`,
					en: `Put it back on the belt`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-why-not-put-meat-back',
		text: {
			da: `I sidste opgave fandt du ud af, at du ikke må lægge kyllingen tilbage på båndet. Hvorfor må du ikke det?`,
			en: `In the previous task you found out that you can't put the chicken back on the belt. Why isn't that allowed?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate product that follows`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det er ligegyldigt, den må gerne komme tilbage på båndet`, 
					en: `It doesn't matter, it can in fact be put back on the belt`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There's a risk of consumers getting sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kødet er blevet sejt og kan ikke bruges`,
					en: `The meat is now tough and can't be used`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-close-to-production',
		text: {
			da: `Du er tæt på produktionen hver dag, du gør en forskel hvis du ser problemer andre overser. Og hvis du ser noget, så har du ansvar for - og pligt til - at informere din nærmeste leder.`,
			en: `You are close to our products every day, and you most likely notice things that others overlook. If you see something, it's your responsibility and duty to inform your closest manager.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-clothes',
		subtype: 'images',
		layout: 's1-m2-clothes',
		text: {
			da: `Det er vigtigt, at dit tøj ikke kommer i berøring med uemballerede produkter. Hvad skal du bruge eller gøre for at undgå det?`,
			en: `It's important that your clothes don't touch non-packaged product. What should you use or do to avoid that?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // apron
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Højblå Armmanchetter
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // Blå handske og skåneærmer
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Plastikposer på armene
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Skudsikker vest
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination',
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der.`,
			en: `Now, let's look at contamination. Contamination is when things get into the products that aren't supposed to be there.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, at der er kommet fremmedlegmer i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp gets broken in production. There's a risk of foreign bodies in the products. What should you do? Place the actions below in the right order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Stop produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tilkald nærmeste leder`,
					en: `Summon closest manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Afvent frigivelse fra nærmeste leder`,
					en: `Wait for release from manager`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-why-contamination-problem',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det kan ødelægge smagen`, 
					en: `It can ruin the taste`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis Kvalitet ser det`,
					en: `Contamination is only a problem if Quality sees it`
				},

			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-good-hygiene-starts-with-you',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-issues',
		layout: 's1-m2-production-issues',
		background: 'production',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du har dem, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'drop',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wrench'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'thread'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-vermin',
		text: {
			da: `Vi SKAL undgå skadedyr i vores fabrikker. Skadedyr er alle andre dyr end kyllinger.`,
			en: `We must avoid getting pests in our plants. Pests are all other animals than chickens.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse',
		text: {
			da: `Du spotter en mus på gulvet på lageret. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in production. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on it while I call for help`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Sørg for at din nærmeste leder også får besked om hændelsen - også selvom I fanger den.`,
								en: `Make sure your closest manager is also notified about the event - even if you manage to catch it.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`, 
					en: `Try to catch the mouse and then call for help if I don't succeed`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell my closest manager at the end of work`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Gå ud og lukke døren, så vi ved hvor den er`,
					en: `Go out and close the door so we know where it is`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-work-on-hygiene-daily',
		text: {
			da: `Der er andre regler, men det her er et godt første skridt. Husk, at hygiejne er noget du skal arbejde med hver dag.`,
			en: `There are other rules, but these are good first steps. Remember that hygiene is something you should work on every day.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-survey',
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på HKScan. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about food safety culture at HKScan. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-authority-responsibility',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and responsibility`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following statement:
				<br />I have authority and responsibility to react if I spot something that can be a hazard to food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-react-if-others-dont-follow-rules',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `React if others do not follow rules`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement:
				<br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-safety-issue-solve-or-inform',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solve/report issues`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement:
				<br />If I see a food safety issue, I'll either solve it or inform my closest manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-reports-leads-to-action',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Reports are acted upon`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement:
				<br />If I report a food safety risk, something will be done to handle it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-finish',
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre kyllinger, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you are now through the second module. If you have three chickens, you have completed the hygiene training. If not, you have to retake the module.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};