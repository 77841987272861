const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-welcome',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal vi i gang med arbejdet. Husk, du skal have tre kyllinger for at gennemføre.`,
			en: `Welcome to the second part of the hygiene retraining. Now we have to start working. Remember, you must get three chickens to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-always-do-before-work',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What must you always do before you start working?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr er rent og ikke er i stykker`,
					en: `Check that my work clothes and equipment are clean and not broken`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Give kollegaerne en high five`, 
					en: `High fiving my colleagues`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Synge HKScan-sangen`,
					en: `Syng the HKScan song`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Vaske hænder`,
					en: `Wash my hands`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du har jo vasket hænder ved håndvasken, da du kom ind i lokalet.`,
								en: `You just washed your hands by the sink as you entered the area.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-production-many-procedures',
		text: {
			da: `Her i produktionen er der mange procedurer, som skal overholdes. For eksempel hvordan vi fylder vogne, kar, kasser og affaldsvogne korrekt.`,
			en: `Here in the production area there are lots of procedures to follow - such as how to fill trays, tubs, crates, and waste carts correctly.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-filled-containers',
		subtype: 'images',
		layout: 's1-m4-filled-containers',
		text: {
			da: `Hvilke affaldsvogne, kar og kasser er fyldt korrekt?`,
			en: `Which waste carts, tubs, and crates have been filled the correct way?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // overfilled trash 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // box filled to right under the edge
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // overfilled box
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // trash filled to the edge
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // overfilled tub
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true, // tub filled with meat to 10 cm below edge
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-4-types',
		text: {
			da: `Der er fire forskellige måder, vores produkter kan blive forurenet på: fysisk, kemisk, mikrobiologisk og af allergener.`,
			en: `There are four different ways in which product can get contaminated: physically, chemically, microbiologically, and by allergens.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-reactions',
		text: {
			da: `Hvordan du skal reagere på forurening afhænger af, hvilken type det er. Lad os kigge nærmere på det.`,
			en: `What to do about it depends on the type of contamination. Let's take a closer look at it.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, at der er kommet hård plast i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp gets broken in production. There's a risk of foreign bodies in the products. What should you do? Place the actions below in the right order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Stop produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tilkald nærmeste leder`,
					en: `Summon closest manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Afvent frigivelse fra nærmeste leder`,
					en: `Wait for release from manager`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-glove-tip-missing',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You notice that the finger tip of one of your plastic gloves is missing. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Informere min nærmeste leder med det samme`,
					en: `Inform my closest manager right away`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`, 
					en: `Continue work. I can change glove in my next break`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Skifte handske, så jeg kan arbejde videre`,
					en: `Change glove so I can continue work`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change glove`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-issues',
		layout: 's1-m4-production-issues',
		background: 'production',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'drop'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'lamp'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'screw'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hammer'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-dripping-water',
		background: 'production',
		backgroundModifier: 'chickens-whole',
		text: {
			da: `Hvis der drypper kondensvand ned fra rør, så er det problematisk. Hvorfor?`,
			en: `If water is dripping from pipes, it's a problem. Why is that?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan forurene produkterne`,
					en: `It can contimate the products`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det er faktisk ikke et problem`, 
					en: `Actually it's not a problem`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There is a risk of consumers getting sick`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-jug-of-cleaning-agent',
		text: {
			da: `Du ser en dunk med rengøringsmiddel stå på båndet. Hvad gør du?`,
			en: `You spot a bottle of cleaner on the conveyer belt. What do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Lader det stå`,
					en: `Leave it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Fjerner det og tilkalder så nærmeste leder`, 
					en: `Remove it and then summon a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt! Vi skal sikre os, at vores produkter ikke bliver forurenet.`,
								en: `Good! We have to make sure our products aren't contaminated.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Tilkalder nærmeste leder`,
					en: `Summon the closest manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Altid godt at spørge, men husk også at fjerne dunken.`,
								en: `It's always good to ask. But don't forget to remove the bottle. `
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fjerner det`,
					en: `Remove it`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt! Men du skal også huske at informere nærmeste leder.`,
								en: `Good! But don't forget to inform a manager.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-ask-if-in-doubt',
		layout: 'spray-cleaner-on-track',
		text: {
			da: `Hvis du er i tvivl om, hvorvidt noget er blevet kemisk forurenet, så spørg din nærmeste leder.`,
			en: `If aren't sure whether or not something has been contaminated chemically, ask your manager.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-why-contamination-problem',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan være sundhedsskadeligt`,
					en: `It can be dangerous`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det kan ødelægge smagen`, 
					en: `It can ruin the taste`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det kan give ekstra CO2-udledning`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Det er faktisk ikke et stort problem`,
					en: `It is actually not that big of a problem`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-first-to-spot-problems',
		text: {
			da: `Hygiejne er ekstremt vigtigt i produktionen. Og fordi du er tæt på produktet, er du den første, der kan lægge mærke til problemer.`,
			en: `Hygiene is extremely important in production. And because you work near the products, you'll be the first to spot problems.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-spot-problem-golden-rule',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Hvad er den gyldne regel, hvis du spotter noget, som måske er et problem?`,
			en: `What is the golden rule if you spot something that could be a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Stands produktionen`,
					en: `Stop production`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Skriv en note`, 
					en: `Write a note`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Smid alt kød i nærheden af problemet ud`,
					en: `Put all meat close to the problem in waste`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Spørg nærmeste leder`,
					en: `Ask the closest manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du bare spørge nærmeste leder.`,
								en: `Exactly! When in doubt, don't hesitate to ask a manager.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-almost-done',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Du er næsten igennem træningen.`,
			en: `You are almost done with the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-hygiene-extra-attention',
		background: 'production',
		backgroundModifier: 'machine-2',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejnen i produktionen?`,
			en: `Why must we be especially aware of hygiene in production?
				<br />Choose 1 - and remember to read all the options:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Because of the law`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because bad hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fordi kunderne forventer det`,
					en: `Because the customers expect it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-more-rules',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Der er flere regler, men det her er et godt første skridt. Husk, at hygiejne er noget du skal arbejde med hver dag.`,
			en: `There are other rules, but these are good first steps. Remember that hygiene is something you should work on every day.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-survey',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på HKScan. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about food safety culture at HKScan. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-authority-responsibility',
		background: 'production',
		backgroundModifier: 'machine-2',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and responsibility`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following statement:
				<br />I have authority and responsibility to react if I spot something that can be a hazard to food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-react-if-others-dont-follow-rules',
		background: 'production',
		backgroundModifier: 'machine-2',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `React if others do not follow rules`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement:
				<br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-safety-issue-solve-or-inform',
		background: 'production',
		backgroundModifier: 'machine-2',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solve/report issues`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement:
				<br />If I see a food safety issue, I'll either solve it or inform my closest manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-reports-leads-to-action',
		background: 'production',
		backgroundModifier: 'machine-2',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Reports are acted upon`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement:
				<br />If I report a food safety risk, something will be done to handle it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-finish',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Godt gået! 
				<br />Hvis du har fået tre kyllinger er du nu igennem træningen. Hvis ikke, skal du tage det her modul en gang til. Husk at spørge en leder eller en kollega, hvis du er i tvivl om noget. 
				<br />God arbejdslyst derude!`,
			en: `Well done!
				<br />If you have three chickens, you have completed the hygiene training. If not, you have to retake the module. Remember to ask a co-worker or a manager if you are in doubt about anything. 
				<br />Have a nice day out there!`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};