const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Velkommen til første del af hygiejnetræningen. Jeg er din kollega og hjælper dig gennem spillet.`,
			en: `Welcome to the first part of the hygiene training. I'm your co-worker, and I'll help you through the game.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Når du får point, kan du også få kyllinger. I toppen af skærmen kan du se, hvor mange kyllinger og point du har.`,
			en: `As you get points, you'll also gain chickens. At the top of your screen, you can see how many points and chickens, you have.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Du skal have tre kyllinger for at gå videre til det næste modul.`,
			en: `You need to get three chickens to advance to the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-first-task',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Første opgave handler om den rigtige påklædning.`,
			en: `The first task is about being dressed properly.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-get-dressed',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I hvilken rækkefølge skal du klæde dig på til at arbejde i produktionen? Sæt handlingerne i den rigtige rækkefølge. Tryk OK, når du mener, du har den.`,
			en: `In what order should you get dressed for work in production? Place the actions in the right order. Click OK when you think you have it.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Bukser & sko eller støvler`,
					en: `Pants & shoes or boots`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Hårnet & skægbind`,
					en: `Hair and beard net`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Kittel`,
					en: `Coat`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Vask hænder`,
					en: `Wash your hands`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hair-and-beard',
		layout: 's1-m1-hair-and-beard',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: `What is the correct way to use hair and beard net? Click on the correct images.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair and ears covered by hair net, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // hair and ears covered by hair net, beard covered by beardnet
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis du ikke har brugt barbermaskine eller skraber inden arbejde, så SKAL du have skægbind på.`,
								en: `If you haven't shaved before work, you MUST wear a beard net.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // hair sticks out, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // hair and ears covered by hair net, beard but no beardnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // ears stick out, no beard
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What kinds of jewelry are NOT allowed in the production area?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // Briller med smykkesten
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // Næsepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // watch
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // necklace
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // vielsesring
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene begins in the locker room. See if you can spot the issues on the next image.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-locker',
		layout: 's1-m1-dressing-room-locker',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in',
		text: {
			da: `Tryk på de 6 ting som er forkerte på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 6 things that are wrong on the image. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glasses'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'work-shoes'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'trash'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'coke'
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sandwich'
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-locker-types',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og sko. Du skal dagligt hente rent arbejdstøj. Arbejdssko skal stå under skabet.`,
			en: `In the locker room there's a locker for your private clothes and shoes. You should pick up clean work clothes on a daily basis. Place your work shoes below the locker.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-private-locker-items',
		layout: 's1-m1-private-locker-items',
		subtype: 'images',
		text: {
			da: `Hvilke ting skal i skabet til privat tøj?`,
			en: `What goes into the locker for private clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // shampoo
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // white work shoes
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `De skal som udgangspunkt stå under skabet. Med mindre du har fået andet at vide.`,
								en: `As a general rule, place these beneath the locker. That is, unless you've been told otherwise.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // hearing protection
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // Hvid kittel med HK logo
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // Halsedisse
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		layout: 's1-m1-image-private-locker-items',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in'
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-ready-production',
		text: {
			da: `Godt, så er vi ved at være klar til at gå ud i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på den.`,
			en: `Okay, I think we're about ready to enter production. On your way there, you'll always pass through a hygiene lock. Let's take a closer look at that.`
		},
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In a hygiene lock, you must always wash your hands. What is the correct order to do that in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med papir`,
					en: `Dry hands with paper towels`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid papir i skraldespand`,
					en: `Put paper towels in bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Brug eventuelt håndsprit`,
					en: `Use sanitizer`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Håndhygiejne handler også om neglene. 
				<br />Tryk på det billede, som er korrekt.`,
			en: `The nails are an important part of hand hygiene. 
				<br />Click the correct image.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // short, clean
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // short, nail polish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // short, slightly dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // long, slightly dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // artificial nails
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
			<br /><br />Vælg 1 - og husk at læse alle mulighederne:`,
		en: `After which situations must you remember to wash your hands?
			<br /><br />Choose 1 - and remember to read all the options:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du går ind i produktionslokaler`,
					en: `When entering a production area`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`, 
					en: `After breaks`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Når du går forbi en håndvask`,
					en: `When passing by a sink`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Når du har rørt ved din mund og næse`,
					en: `When you've touched your mouth or nose`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Efter toiletbesøg`, 
					en: `After being to the toilet`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all of the situations above`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-you-make-food',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Husk du fremstiller fødevarer som andre skal spise, og som du måske også selv skal spise.`,
			en: `Remember, you make foods that others - and possibly yourself - will eat.`
		},
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-finish',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Sådan, du er nu færdig med første modul. Hvis du har fået tre kyllinger, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you've completed the first module. If you have three chickens, you can continue to the next module. If not, you'll have to complete this module once more.`
		},
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};