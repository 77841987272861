// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const playerUiTexts = {
	/* Login */
	workerId: {
		da: 'Er dette dit medarbejdernummer?',
		en: 'Is this your employee number?',
		pl: 'Czy to Twów numer pracownika?',
		de: 'Ist das Ihre Mitarbeiternummer?'
	},
	yes: {
		da: 'Ja',
		en: 'Yes',
		pl: 'Tak',
		de: 'Ja'
	},
	no: {
		da: 'Nej',
		en: 'No',
		pl: 'Nie',
		de: 'Nein'
	},

	/* Lobby */
	noGame: {
		da: 'Der findes ikke noget spil under denne URL',
		en: 'There is no game connected to this URL',
		pl: 'Z tym adresem URL nie jest powiązana żadna gra',
		de: 'Mit dieser URL ist kein Spiel verbunden'
	},
	playerDiffGameConfirmId: {
		da: `Det ser ud til dit medarbejdernummer %userId% hører til på %gameName%. 
			<br /><br />Er du sikker på, det er dit medarbejdernummer?`,
		en: `It looks like you employee id %userId% is connected to %gameName%.
			<br /><br />Are you sure it is your employee id?`,
		pl: `Wygląda na to, że Twój numer pracownika %userId% należy do %gameName%. 
			<br /><br />Czy na pewno jest to Twój numer pracownika?`,
		de: `Offenbar ist Ihre Mitarbeiter-ID %userId% mit %gameName% verbunden.
			<br /><br />Sind Sie sicher, dass es sich um Ihre Mitarbeiter-ID handelt?`
	},	
	noLogOut : {
		da: 'Nej, log ud',
		en: 'No, log out',
		pl: 'Nie, wyloguj się',
		de: 'Nein, melden Sie sich ab'
	},
	playerDiffGame: {
		da: `Du er allerede i gang med %gameName%.
			<br/><br/>Du kan kun være i gang med ét spil ad gangen.`,
		en: `You are currently connected to %gameName%.
			<br /><br />You can only be connected to one game at a time.`,
		pl: `Jesteś obecnie połączony z %gameName%. 
			<br /><br />W danym momencie możesz być połączony tylko z jedną grą.`,
		de: `Sie sind derzeit mit %gameName% verbunden.
			<br /><br />Sie können jeweils nur mit einem Spiel verbunden sein.`
	},
	gameDeleted: {
		da: '??? (slettet)',
		en: '??? (deleted)',
		pl: '??? (usunięte)',
		de: '??? (gelöscht)'
	},
	goToGameBtn: {
		da: 'Gå til %gameName%',
		en: 'Go to %gameName%',
		pl: 'Przejdź do %gameName%',
		de: 'Gehe zu %gameName%'
	},
	switchGameBtn: {
		da: 'Skift til %gameName%',
		en: 'Switch to %gameName%',
		pl: 'Przełącz na %gameName%',
		de: 'Wechseln Sie zu %gameName%'
	},
	switchGameWarning: {
		da: `Du kan kun være i gang med ét spil ad gangen.
			<br /><br/>Hvis du skifter til %gameName2% vil al din data for %gameName1% blive slettet.
			<br /><br/>Er du sikker på, du vil skifte til %gameName2%?`,
		en: `You can only be connected to one game at a time.
			<br /><br />If you switch to %gameName2% all your progress in %gameName1% will be lost.
			<br /><br />Are you sure you want to switch to %gameName2%?`,
		pl: `Możesz być połączony tylko z jedną grą naraz.
			<br /><br />Jeśli przełączysz się na %gameName2%, wszystkie Twoje postępy w %gameName1% zostaną utracone.
			<br /><br />Czy na pewno chcesz przełączyć się na %gameName2%?`,
		de: `Sie können jeweils nur mit einem Spiel verbunden sein.
			<br /><br />Wenn Sie zu %gameName2% wechseln, gehen alle Ihre Fortschritte in %gameName1% verloren.
			<br /><br />Sind Sie sicher, dass Sie zu %gameName2% wechseln möchten?e you want to switch to %gameName2%?`
	},
	cancelBtn: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren'
	},
	confirmBtn: {
		da: 'Ja, skift spil',
		en: 'Yes, switch game',
		pl: 'Tak, zmień grę',
		de: 'Ja, Spiel wechseln'
	},

	/* Choose language */
	chooseLanguage: {
		da: 'Vælg sprog',
		en: 'Choose language',
		pl: 'Wybierz język',
		de: 'Sprache wählen'
	},

	/* Player info */
	playerInfo: {
		da: 'Indtast dit navn og e-mail for at spille.',
		en: 'Enter your name and e-mail to player.'
	},
	playerInfoName: {
		da: 'Navn',
		en: 'Name'
	},
	playerInfoEmail: {
		da: 'E-mail',
		en: 'E-mail'
	},


	/* Module overview */
	reset: {
		da: 'Reset',
		en: 'Reset',
		pl: 'Zresetuj',
		de: 'Zurücksetzen'
	},

	moduleOverview: {
		da: 'Moduloversigt',
		en: 'Module overview',
		pl: 'Przegląd modułów',
		de: 'Modul Übersicht'
	},
	employeeNumber: {
		da: 'Medarbejder-nr.',
		en: 'Employee no.',
		pl: 'Numer pracownika',
		de: 'Mitarbeiter-Nr.'
	},

	/* Module - general */
	continue: {
		da: 'Videre',
		en: 'Next'
	},
	ok: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		de: 'OK'
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
		pl: 'Wybierz',
		de: 'Wähle',
	},
	option: {
		da: 'svar',
		en: 'option',
		pl: 'Opcja',
		de: 'Möglichkeit'
	},
	options: {
		da: 'svar',
		en: 'options',
		pl: 'Opcje',
		de: 'Optionen'
	},
	finishBtn: {
		da: 'Afslut',
		en: 'Finish',
		pl: 'Zakończ',
		de: 'Beenden'
	},

	/* Module - results */
	results: {
		da: 'resultat',
		en: 'results',
		pl: 'wyniki',
		de: 'Ergebnisse'
	},
	stars: {
		da: 'Kyllinger',
		en: 'Chickens',
		pl: 'Chickens', // TODO
		de: 'Chickens' // TODO
	},
	points: {
		da: 'Point',
		en: 'Points',
		pl: 'Punkty',
		de: 'Punkte'
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
		pl: 'Spędzony czas',
		de: 'Zeitaufwand'
	},
	avgErrors: {
		da: 'Antal fejl per opgave (gennemsnit)',
		en: 'Average mistakes per task',
		pl: 'Średnia liczba błędów na zadanie',
		de: 'Durchschnittliche Fehler pro Aufgabe'
	},
	noErrorsTasks: {
		da: 'Antal opgaver løst uden fejl',
		en: 'Number of tasks solved without mistakes',
		pl: 'Liczba zadań rozwiązanych bez błędów',
		de: 'Anzahl der fehlerfrei gelösten Aufgaben'
	},
	backToModuleOverview: {
		da: 'Tilbage til moduloversigt',
		en: 'Back to module overview',
		pl: 'Powrót do przeglądu modułów',
		de: 'Zurück zur Modulübersicht'
	},
	tryAgain: {
		da: 'Prøv igen',
		en: 'Try again',
		pl: 'Spróbuj ponownie',
		de: 'Versuchen Sie es erneut'
	},
	confirmTryAgainPopup: {
		title: {
			da: 'Prøv igen',
			en: 'Try again'	,
			pl: 'Spróbuj ponownie',
			de: 'Versuche es erneut'
		},
		text: {
			da: 'Vil du prøve at slå din egen score? Du kan nulstille modulet og spille igen.',
			en: 'Want to try to beat your own score? You can reset the module and play again.',
			pl: 'Chcesz spróbować pobić swój własny wynik? Możesz zresetować moduł i grać ponownie.',
			de: 'Möchtest Du versuchen, deinen eigenen Punktestand zu übertreffen? Du kannst das Modul zurücksetzen und erneut spielen.'
		},
		resetBtn: {
			da: 'Nulstil',
			en: 'Reset',
			pl: 'Zresetuj',
			de: 'Zurücksetzen'
		},
		cancelBtn: {
			da: 'Afbryd',
			en: 'Cancel',
			pl: 'Anuluj',
			de: 'Stornieren'
		}
	},

	/* Module - survey task */
	surveyChooseXOptions: {
		da: 'Vælg %X%',
		en: 'Choose %X%'
	},
	surveyChooseMaxXOptions: {
		da: 'Vælg op til %X%',
		en: 'Choose up to %X%'
	},

	/* Module - order task */
	orderPopup: {
		title: {
			da: 'Du fik %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
			pl: 'Masz %correctAnswers%/%totalAnswers%.',
			de: 'Du hast %correctAnswers%/%totalAnswers% erhalten.'
		},
		text: {
			da: 'Prøv igen!',
			en: `Try again!`,
			pl: 'Spróbuj ponownie!',
			de: 'Versuche es erneut!'
		}
	},

	/* Module - spot errors task */
	spotErrorsPopup: {
		title1: {
			da: 'Ups!',
			en: 'Oops!',
			pl: 'Ups!',
			de: 'Hoppla!'
		},
		text1: {
			da: 'Du fandt kun %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You only %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znalazłeś tylko %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Du hast nur %spottedErrors% von %numberOfErrors% Fehlern.'
		},
		title2: {
			da: 'Ikke dårligt!',
			en: 'Not bad!',
			pl: 'Nieźle!',
			de: 'Nicht schlecht!'
		},
		text2: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Zauważyłeś %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Du hast %spottedErrors% von %numberOfErrors% Fehlern entdeckt.'
		},
		title3: {
			da: 'Godt klaret!',
			en: 'Well done!',
			pl: 'Dobra robota!',
			de: 'Gut gemacht!'
		},
		text3: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Zauważyłeś %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Sie haben %spottedErrors% von %numberOfErrors% Fehlern entdeckt.'
		},
	},

	/* Module - talk to facilitator popup */
	talkToFacilitatorPopup: {
		text: {
			da: 'Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.',
			en: 'You have had too many errors in this module. Talk to your facilitator about how to proceed.',
			pl: 'W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o tym, jak postępować.',
			de: 'In diesem Modul sind zu viele Fehler aufgetreten. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.'
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			de: 'Ok'
		}
	},

	/* Module - locked popup */
	moduleLockedPopup: {
		text: {
			da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.
				<br /><br />Modulet er låst indtil %date%.`,
			en: `You have had too many errors in this module. Talk to your facilitator about how to proceed. 
				<br /><br />This module will be locked until %date%`,
			pl: `W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o tym, jak postępować. 
				<br /><br />Ten moduł będzie zablokowany do dnia %date%`,
			de: `Du hast in diesem Modul zu viele Fehler gemacht. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.
				<br /><br />Dieses Modul wird bis zum %date% gesperrt.`
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			de: 'Ok'
		}
	}
};

module.exports = {
	playerUiTexts
};